








import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import report from '@/components/Report/ElementaryReport.vue'
import YearlyReport from '@/components/Report/YearlyReport.vue'
import MonthlyReport from '@/components/Report/MonthlyReport.vue'
import { MonthlyGradeController } from '@/services/request.service'
const operations: any = {
  monthly: function(periodId, studentId) {
    return MonthlyGradeController.getMonthlyReport(periodId, studentId)
  },
}

@Component({
  components: {
    MonthlyReport,
  },
})
export default class ExportReport extends Vue {
  private data: any = {}

  private loaded: boolean = false

  private getDetail(studentId, periodId): void {
    MonthlyGradeController.getMonthlyReport(periodId, studentId)
      .then(res => {
        this.data = res.data
        // this.$set(this.data, 'reportDate', this.reportDate)
      })
      .catch(err => console.log(err))
      .finally(() => (this.loaded = true))
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    const { studentId, periodId } = to.params
    this.getDetail(studentId, periodId)
  }
}
